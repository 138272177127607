import React from "react";
import { graphql, Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import Layout from "../components/layout";
import Form from "../components/views/careers/sections/Form";

const careerTemplate = (props) => {
  const careerListData = props.data.careers;
  const careerDetail = careerListData.detailsList.filter(
    (career) => career.slug === props.pageContext.slug
  )[0];
  const { title, description, positions, location } = careerDetail;
  return (
    <Layout>
      <section className="section">
        <div className="container pt-10 pb-4">
          <div className="career-content">
            <div style={{ margin: "20px 0" }}>
              <Link
                to={"/careers"}
                className="career-back-btn"
                title={`careers page link`}
              >
                Back to careers
              </Link>
            </div>
            <div className="row career-single-header mb-4">
              <div className="col-8">
                <div className="d-flex justify-content-between pb-1">
                  <h1>{title}</h1>
                </div>
                <div className="card-title career-info-text">
                  {location}
                  <span className="career-job-time-text">{"Full time"}</span>
                  <span className="career-openings">
                    {positions} {positions > 1 ? "open roles" : "open role"}
                  </span>
                </div>
              </div>
              <div className="col-4 text-right pr-4">
                <Link
                  to={`${props.path}/#apply`}
                  className="btn btn-primary btn-lg btn-rounded career-btn"
                  title={`apply link`}
                >
                  Apply now
                </Link>
              </div>
            </div>
            <div>
              <ReactMarkdown
                source={description}
                className="muted"
                style={{ padding: "0 50px" }}
              />
            </div>
          </div>
        </div>
        <div className="container pt-2 p-4" id="apply">
          <div className="career-content">
            <h3 className="mb-5">Submit Your Application</h3>
            <div className="row">
              <div className="col-md-10">
                <Form title={title} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query getCareerDetails($slug: String) {
    careers: strapiCareerDetails(
      detailsList: { elemMatch: { slug: { eq: $slug } } }
    ) {
      detailsList {
        slug
        positions
        title
        location
        id
        link
        description
        cardId
        buttonName
      }
      title
    }
  }
`;

export default careerTemplate;
