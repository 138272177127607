import React, { useState, useEffect } from "react";
import ErrorField from "../../../common/ErrorField";
import { formJobApplication } from "../../../../config";
import {
  validateEmail,
  isEmpty,
  isFile
} from "../../../../functions";

const Form = ({ title }) => {
  const [designation, setDesignation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phone, setPhone] = useState("");
  const [fileErr, setFileErr] = useState("");

  useEffect(() => {
    setDesignation(title);
  }, []);

  const validateForm = () => {
    let isValid = true;
    if (!isEmpty(firstName)) {
      setFirstNameErr("Name cannot be empty!");
      isValid = false;
    }
    if (!isEmpty(email)) {
      setEmailErr("Email cannot be empty!");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailErr("Enter valid email!");
      isValid = false;
    }
    if (!isFile("resumeFile")) {
      setFileErr("Please upload a file!");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = (event) => {
    const isValid = validateForm();
    if (!isValid) {
      event.preventDefault();
    }
  };

  return (
    <form
      action={formJobApplication}
      method="POST"
      name="form"
      acceptCharset="UTF-8"
      encType="multipart/form-data"
      id="form"
      className="form career-form"
      onSubmit={onSubmit}
    >
      <input type="hidden" name="zf_referrer_name" defaultValue />
      {/* To Track referrals , place the referrer name within the " " in the above hidden input field */}
      <input type="hidden" name="zf_redirect_url" defaultValue />
      {/* To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field */}
      <input type="hidden" name="zc_gad" defaultValue />
      {/* If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM */}
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="bold">First Name</label>
            <input
              type="text"
              maxLength="255"
              name="Name_First"
              fieldtype="7"
              className="form-control bg-contrast"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
            <ErrorField errMessage={firstNameErr} />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="bold">Last Name</label>{" "}
            <input
              type="text"
              maxLength="255"
              name="Name_Last"
              fieldtype="7"
              className="form-control bg-contrast"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="bold">Email</label>{" "}
        <input
          fieldtype="9"
          type="text"
          maxLength="255"
          name="Email"
          checktype="c5"
          className="form-control bg-contrast"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <small
          id="emailHelp"
          className="form-text text-secondary mt-1 mb-2 italic"
        >
          We'll never share your email with anyone else.
        </small>
        <ErrorField errMessage={emailErr} />
      </div>
      <div className="form-group">
        <label className="bold">Phone No</label>
        <input
          type="text"
          compname="PhoneNumber"
          name="PhoneNumber_countrycode"
          maxLength="20"
          checktype="c7"
          phoneformat="1"
          isCountryCodeEnabled="false"
          fieldtype="11"
          id="international_PhoneNumber_countrycode"
          valtype="number"
          phoneformatType="2"
          className="form-control bg-contrast"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          required
        />
      </div>
      <div className="form-group d-none">
        <label className="bold">Designation</label>{" "}
        <input
          type="text"
          name="SingleLine"
          value={designation}
          checktype="c1"
          maxLength="255"
          fieldtype="1"
          className="form-control bg-contrast"
          required
        />
      </div>
      <div className="form-group">
        <label className="bold">Upload Your Resume</label>
        <input
          id="resumeFile"
          type="file"
          name="FileUpload"
          checktype="c1"
          accept="application/pdf"
          className="d-block"
        />
        <ErrorField errMessage={fileErr} />
      </div>
      <div className="form-group mt-4 pt-4">
        <button
          className="zf-submitColor btn btn-lg btn-primary btn-rounded font-weight-normal"
          data-loading-text="Sending..."
          name="submit"
          type="submit"
        >
          Submit
        </button>
      </div>

      {/* 'zf-templateWrapper' ends */}
    </form>
  );
};

export default Form;
